import { FIXED_PRICE_PLAN, ON_DEMAND_PLAN } from '../../app/api/constants';

export const planLabel = (plan) => {

  switch (plan) {
    case FIXED_PRICE_PLAN: {
      return 'Fixed Price';
    }
    case ON_DEMAND_PLAN: {
      return 'On Demand';
    }
  }
}

export const accountTypeLabel = (state, plan) => {
  switch (state) {
    case 'DD': {
      return planLabel(plan);
    }
    case 'FREE': {
      return 'Free';
    }
    case 'TRIAL': {
      return 'Trial';
    }
    case 'EXPIRED': {
      return 'Expired Trial';
    }
  }
}