import React from 'react';

import Layout from '../../components/layout';

import { DetailRow } from '../../components/widgets/grid';
import { StartTrial } from '../../components/widgets/trial';
import { ProfileStatus } from '../../components/state/profile';
import { PageTitle } from '../../components/title';

import { nationalize } from '../../utils/phoneSyntax';
import { locales } from '../../utils/locales';

import { accountTypeLabel } from '../../app/nomenclature/billing';

const Dashboard = ({user, number, account, billing: { state, plan }}) => {

  const canStartTrial = state === 'FREE';
  const label = accountTypeLabel(state, plan);

  return (
    <div className="mb4">

      <DetailRow>
        <div segment='true'>Your Name</div>
        <div prose='true'>{user.name}</div>
      </DetailRow>

      <DetailRow>
        <div segment='true'>Your Number</div>
        <div prose='true'>{number}</div>
      </DetailRow>

      <DetailRow>
        <div segment='true'>Company</div>
        <div prose='true'>{account.name}</div>
      </DetailRow>

      <DetailRow>
        <div segment='true'>Account Type</div>
        <div prose='true'>{label}</div>
        { canStartTrial && <StartTrial action /> }
      </DetailRow>

    </div>
  )
}

const DashboardStatus = ({ data : { profile } }) => {

  const account = profile.accounts[0];
  const { user } = profile;
  const number = nationalize(user.number, locales);

  const { billing } = account;

  return (
    <div>
      <PageTitle title="Dashboard"/>
      <Dashboard account={account} user={user} number={number} billing={billing}/>
    </div>
  )
};

const DashboardPage = () => (
  <Layout>
    <ProfileStatus>
      <DashboardStatus/>
    </ProfileStatus>
  </Layout>
)

export default DashboardPage;