import { SettingsQuery } from '../graph/queries/SettingsQuery';
import { SelectBillingPlanMutation } from '../graph/mutations/SelectBillingPlanMutation';
import { StartTrialMutation } from '../graph/mutations/StartTrialMutation';

import { accountId } from '../graph/cache/account';

export const startTrial = async (client) => {

  let { account, error } = accountId(client);

  const request = {
    mutation: StartTrialMutation,
    variables: {
      account,
    },
    refetchQueries: [{
      query: SettingsQuery,
      variables: {},
    }],
  };

  return client.mutate(request)
  .then(({data, error}) => ({ data, error }))
  .catch((error) => ({ error }));
}

export const selectBillingPlan = (plan) => async (client) => {

  let { account, error } = accountId(client);

  const request = {
    mutation: SelectBillingPlanMutation,
    variables: {
      account, plan,
    },
    refetchQueries: [{
      query: SettingsQuery,
      variables: {},
    }],
  };

  return client.mutate(request)
  .then(({data, error}) => ({ data, error }))
  .catch((error) => ({ error }));
}