import React from 'react';

// const style = 'f6 grow hover-yellow no-underline br-pill ph4 pv2 mb2 dib white bg-mid-gray';
// Note absense of `dib` class
const style = 'f6 grow hover-yellow no-underline br-pill ph4 pv2 white bg-mid-gray';

export const Button = ({onClick, label}) =>
  <div onClick={onClick} className="mb2">
    <a className={style} href="#0">
      {label}
    </a>
  </div>

export const RedirectButton = ({url, label}) =>
  <div>
    <a className={style} href={url}>
      {label}
    </a>
  </div> 
