import React from 'react';

import { Query } from 'react-apollo';

import Busy from '../widgets/busy';
import Error from '../widgets/error';

import { SettingsQuery } from '../../app/graph/queries/SettingsQuery';

export const ProfileStatus = ({children}) => (
  <Query query={SettingsQuery}>
    {({ loading, error, data }) => {
      if (loading) return (<Busy/>);
      if (error) {
        return (<Error error={error}/>);
      }
      return React.Children.map(children,
        child => React.cloneElement(child, {data, })
      )
    }}
  </Query>
);