import { selectBillingPlan, startTrial } from '../api/billing';
import { onError } from './errors';

export const billingEffects = {

  commenceTrial: () => async ({ state, setState }) => {

    const { client } = state; 
    const { data, error } = await startTrial(client);

    if (error) {
      const { auth } = state;
      onError(error, auth);
    }
  },

  selectPlan: (plan) => async ({ state, setState }) => {

    const { client } = state; 
    const { error } = await selectBillingPlan(plan)(client);
    
    if (error) {
      const { auth } = state;
      onError(error, auth);
    }
    
  },
};