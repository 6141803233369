import React from 'react';

import { Container } from 'constate';

import { billingEffects } from '../../app/effects/billing';
import { Button } from '../../components/widgets/button';

export const StartTrial = () =>
  <Container
    action
    context='primary'
    effects={billingEffects}>
    {({commenceTrial}) => (
        <Button action label={'Start Trial'} onClick={commenceTrial} />
    )}
  </Container>